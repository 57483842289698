exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ayuda-js": () => import("./../../../src/pages/ayuda.js" /* webpackChunkName: "component---src-pages-ayuda-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-prueba-js": () => import("./../../../src/pages/prueba.js" /* webpackChunkName: "component---src-pages-prueba-js" */),
  "component---src-pages-scouting-play-js": () => import("./../../../src/pages/scouting-play.js" /* webpackChunkName: "component---src-pages-scouting-play-js" */),
  "component---src-pages-suscripciones-js": () => import("./../../../src/pages/suscripciones.js" /* webpackChunkName: "component---src-pages-suscripciones-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-veo-cam-js": () => import("./../../../src/pages/veo-cam.js" /* webpackChunkName: "component---src-pages-veo-cam-js" */)
}

