module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TQC2WTP5","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Scouting Labs","short_name":"ScoutingLabs","start_url":"/","background_color":"#03000d","display":"standalone","icon":"src/assets/favicon/favicon.svg","icons":[{"src":"src/assets/favicon/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"src/assets/favicon/favicon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"src/assets/favicon/favicon.ico","sizes":"16x16","type":"image/x-icon"},{"src":"src/assets/favicon/web-app-manifest-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/assets/favicon/web-app-manifest-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"52ca98e2c27be867a32a5b438a434737"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["es","en"],"defaultLanguage":"es","siteUrl":"https://scoutinglabs.com/","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/ignored-page","languages":["es"]}],"redirect":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
